<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <v-form ref="form" lazy-validation>
          <v-layout wrap>
            <v-flex xs12 pt-2>
              <v-card>
                <v-card-title class="elevation-1">
                  Promocode Report
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-data-table
                  :headers="headers"
                  :items="report"
                  :search="search"
                  hide-default-footer
                  class="elevation-1"
                >
                  <template v-slot:[`item.name`]="{ item }">
                    <span
                      >{{ item.buyer.firstname }}
                      {{ item.buyer.lastname }}</span
                    >
                  </template>
                  <template v-slot:[`item.amount`]="{ item }">
                    <span>Rs. {{ item.amount}}</span>
                  </template>
                   <template v-slot:[`item.promoCodeDiscount`]="{ item }">
                    <span>Rs. {{ item.promoCodeDiscount}}</span>
                  </template>
                  <template v-slot:[`item.date`]="{ item }">
                    <span>{{ item.init_date.slice(0, 10) }}</span>
                  </template>
                </v-data-table>
                <div class="pt-2">
                  <v-pagination
                    :length="pages"
                    :total-visible="7"
                    v-model="currentPage"
                    color="#FF0000"
                  ></v-pagination>
                </div>
              </v-card>
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  components: {},
  data() {
    return {
      checkbox: true,
      ServerError: false,
      file: null,
      showsnackbar: false,
      msg: null,
      page: 1,
      currentPage: 1,
      pages: 0,
      limit: 12,
      code: null,
      widthOfCard: "250px",
      addslider: false,
      appLoading: false,
      dialogDelete: false,
      search: "",
      report: [],
      headers: [
        { text: "Buyer Name", value: "name" },
        { text: "Phone", value: "buyer.phone" },
        { text: "Order No", value: "orderno" },
        { text: "Method", value: "method" },
        { text: "Amount", value: "amount" },
        { text: "Promo Code Discount", value: "promoCodeDiscount" },
        { text: "Promo Code Type", value: "promoCodeType" },
        { text: "Date", value: "date" },
        // { text: "Actions", value: "_id", sortable: false },
      ],
    };
  },
  watch: {
    currentPage() {
      this.getData();
    },
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/report/PromoCode/Purchase/Details",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
          limit: this.limit,
          page: this.currentPage,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.report = response.data.data;
          this.pages = Math.ceil(response.data.totalLength / this.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>
